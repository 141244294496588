/**
 * BlogAuthorOverview Page
 * @namespace BlogAuthorOverviewPage
 */
import React from "react";

import DatePicker from "react-datepicker";
import Modal from "semantic-ui-react/dist/commonjs/modules/Modal";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Form from "semantic-ui-react/dist/commonjs/collections/Form";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Image from "semantic-ui-react/dist/commonjs/elements/Image";
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment";
import TextArea from "semantic-ui-react/dist/commonjs/addons/TextArea";
import {getTokenFromLocalStorage} from "../../helper/util";
import config from "../../config/main.config";
import {observer} from "mobx-react";
import userStore from "../../stores/userStore";
import history from "../../helper/browserHistory";


let Editor = null;
if (typeof window !== "undefined") {
    Editor = require("../../components/moap-components/TextEditor").default
}


@observer
class BlogAuthorEditPage extends React.Component {

    //For Dates
    /*
     * Array of objects:
     * {
     *  id: text (UUID -> only when editing)
     *  date: date,
     *  title: string,
     *  text: string
     * }
*/
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            isEditSource: false,
            categories: [],
            headerWrong: false,
            thumbnailWrong: false,
            linkWrong: false,
            descriptionWrong: false,
            id: 0,
            pageHeader: "Neuer Artikel",
            buttonText: "Erstellen",
            editing: false,
            availableImages: [],
            showAvailableModal: false,
            date: new Date(),
            errorMessage: "",
            errorModalOpen: false,
            articleDates: [],
            downloads: [],
            images: [],
            article: {
                text: "",
                header: "",
                thumbnail: "",
                description: "",
                tags: "",
                published: 0,
                categoryIds: [],
                showOnProjectsPage: false
            }
        }
    }

    componentDidMount() {
        if (userStore.userFromServer == null) {
            history.push("/");
        }
        let id = this.props.match.params.id;

        if (id !== "0") {
            this.setState({
                ...this.state,
                editing: true,
                pageHeader: "Artikel Bearbeiten",
                buttonText: "Bearbeiten",
                id: id
            });

        }
        fetch(config.BASE_URL + 'users/categories', {
            method: 'GET',
            headers: {
                "Authorization": "Bearer " + getTokenFromLocalStorage(),
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then((resp) => resp.json())
            .then(response => {
                let categories = response.subcategories.filter((category) => category.id !== "5c6d321b-fbe7-4f30-89d9-912af45d5359");
                for (let i = 0; i < categories.length; i++) {
                    categories[i].checked = false;
                }
                this.setState({...this.state, categories: categories})
                if (this.state.article.categoryIds.length > 0) {
                    this.setPresetCategories();
                }
                this.setState({...this.state})
            })
            .catch(function (error) {
            });

        fetch(config.BASE_URL + 'users/article-edit/' + id, {
            method: 'GET',
            headers: {
                "Authorization": "Bearer " + getTokenFromLocalStorage(),
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then((resp) => resp.json())
            .then(article => {
                this.setState({...this.state, article: article, loaded: true})
                if (this.state.categories.length > 0) {
                    this.setPresetCategories();
                }
                this.setState({...this.state})
            })
            .catch(function (error) {
                console.log(error);
            });

        fetch(config.BASE_URL + 'blog/date/' + id, {
            method: 'GET',
            headers: {
                "Authorization": "Bearer " + getTokenFromLocalStorage(),
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then((resp) => resp.json())
            .then(response => {
                let dates = [];

                for (let i = 0; i < response.dates.length; i++) {
                    dates.push({
                        id: response.dates[i].id,
                        text: response.dates[i].text,
                        title: response.dates[i].title,
                        date: new Date(response.dates[i].date)
                    })
                }
                this.setState({...this.state, articleDates: dates})
                //  this.articleDates = response.dates;
            })
            .catch(function (error) {
                console.log(error);
            });
        fetch(config.BASE_URL + 'blog/download/' + id, {
            method: 'GET',
            headers: {
                "Authorization": "Bearer " + getTokenFromLocalStorage(),
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then((resp) => resp.json())
            .then(response => {
                let downloads = [];
                for (let i = 0; i < response.downloads.length; i++) {
                    downloads.push({
                        id: response.downloads[i].id,
                        title: response.downloads[i].title,
                        link: response.downloads[i].link,
                    })
                }
                this.setState({...this.state, downloads: downloads})
            }).catch(function (error) {
            console.log(error);
        });
        fetch(config.BASE_URL + 'blog/image/' + id, {
            method: 'GET',
            headers: {
                "Authorization": "Bearer " + getTokenFromLocalStorage(),
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then((resp) => resp.json())
            .then(response => {
                let images = [];
                for (let i = 0; i < response.images.length; i++) {
                    images.push({
                        id: response.images[i].id,
                        link: response.images[i].link,
                    })
                }
                this.setState({...this.state, images: images})
            }).catch(function (error) {
            console.log(error);
        });
        window.scrollTo(0, 0);

    }

    addArticleDate() {
        this.state.articleDates.push({
            id: "",
            date: new Date(),
            title: "",
            text: ""
        });
        this.setState({...this.state});
    }

    addArticleDownload() {
        this.state.downloads.push({id: "", title: "", link: ""});
        this.setState({...this.state});
    }

    handleDateChange(index, date) {
        if (date === null) {
            console.log("ERROR");
        } else {
            this.state.articleDates[index].date = date;
        }
        this.setState({...this.state});
    }

    handleDateInputChange(type, index, event) {
        let self = this.state;
        switch (type) {
            case 'title':
                self.articleDates[index].title = event.target.value;
                break;
            case 'text':
                self.articleDates[index].text = event.target.value;
                break;
            default:
                break;
        }
        this.setState({...this.state});
    }

    handleDownloadInputChange(type, index, event) {
        let self = this.state;
        switch (type) {
            case 'title':
                self.downloads[index].title = event.target.value;
                break;
            case 'link':
                self.downloads[index].link = event.target.value;
                break;
            default:
                break;
        }
        this.setState({...this.state});
    }

    handleClose() {
        this.setState({...this.state, showAvailableModal: false});
    }

    createArticle() {
        if (this.areMissingFields()) {
            return;
        }
        let httpType = 'POST';
        let newArticle = {
            id: this.state.id,
            header: this.state.article.header,
            thumbnail: this.state.article.thumbnail,
            description: this.state.article.description,
            published: this.state.article.published,
            text: this.state.article.text,
            tags: this.state.article.tags,
            categories: this.getChosenCategoryId(),
            dates: this.state.articleDates,
            downloads: this.state.downloads,
            images: this.state.images,
            showOnProjectsPage: this.state.article.showOnProjectsPage
        };
        if (this.state.editing) {
            httpType = 'PUT';
        }

        fetch(config.BASE_URL + 'blog/article', {

            method: httpType,
            headers: {
                "Authorization": "Bearer " + getTokenFromLocalStorage(),
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(newArticle)
        }).then((resp) => resp.json())
            .then(article => {
                this.props.history.push('/blog-author-overview');
            })
            .catch(function (error) {
                console.log(error);
            });

    }

    areMissingFields() {
        let missing = false;


        if (this.state.article.header === "") {
            this.setState({...this.state, headerWrong: true});
            missing = true;
        } else {
            this.setState({...this.state, headerWrong: false});
        }

        if (this.state.article.description === "") {
            this.setState({...this.state, descriptionWrong: true});
            missing = true;
        } else {
            this.setState({...this.state, descriptionWrong: false});
        }

        return missing;
    }

    setThumbnail(url) {
        this.setState(prevState => ({...prevState, article: {...prevState.article, thumbnail: url}}));
        this.setState({...this.state, showAvailableModal: false});
    }

    uploadThumbnailImage(event) {
        let self = this;
        const data = new FormData();
        data.append('image', event.target.files[0]);

        let chosenCategory = null;
        for (let i = 0; i < this.state.categories.length; i++) {
            if (this.state.categories[i].checked) {
                chosenCategory = this.state.categories[i].id;
                break;
            }
        }
        if (chosenCategory == null && this.state.categories.length > 0) {
            chosenCategory = this.state.categories[0].id;
        }
        data.append('category', chosenCategory);

        fetch(config.BASE_URL + 'blog/image', {
            method: "POST",
            body: data,
            headers: {
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            }
        }).then(function (response) {
            if (response.status >= 200 && response.status < 300) {
                response.json().then(json => {
                    self.state.article.thumbnail = json.link;
                    self.setState({...self.state});
                });
            }
        }).catch((e) => {
            console.log(e);
        });
        this.setState({...this.state});
    }

    uploadGalleryImage(event) {
        let self = this;
        const data = new FormData();

        let files = Array.from(event.target.files);

        for (let i = 0; i < files.length; i++) {
            let file = files[i];
            data.append('images', file);
            if (i === 9) {
                break;
            }
        }
        fetch(config.BASE_URL + 'blog/images', {
            method: "POST",
            body: data,
            headers: {
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            }
        }).then(function (response) {
            if (response.status >= 200 && response.status < 300) {
                response.json().then(json => {
                    self.state.images = self.state.images.concat(json);
                    self.setState({...self.state});
                });
            }
        }).catch((e) => {
            console.log(e)
        });
        this.setState({...this.state});
    }

    uploadLink(index, event) {
        let self = this;
        const data = new FormData();
        data.append('pdf', event.target.files[0]);
        fetch(config.BASE_URL + 'blog/pdf', {
            method: "POST",
            body: data,
            headers: {
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            }
        }).then(function (response) {
            if (response.status >= 200 && response.status < 300) {
                response.json().then(json => {
                    self.state.downloads[index].link = json.link;
                    self.setState({...self.state});
                });
            }
        }).catch((e) => {
            console.log(e)
        });
        this.setState({...this.state});
    }

    publishedChanged(e, {checked}) {
        this.setState(prevState => ({...prevState, article: {...prevState.article, published: checked}}));
    }

    changeCategory(index) {
        for (let i = 0; i < this.state.categories.length; i++) {
            if (i === index) {
                this.state.categories[i].checked = !this.state.categories[i].checked
                this.setState({...this.state})
            } else {
                //   this.categories[i].checked = false;
            }
        }
    }

    getChosenCategoryId() {
        let categories = [];
        for (let i = 0; i < this.state.categories.length; i++) {
            if (this.state.categories[i].checked) {
                categories.push(this.state.categories[i].id)
            }
        }
        return categories;
    }

    setPresetCategories() {
        for (let i = 0; i < this.state.categories.length; i++) {
            if (this.state.article.categoryIds.includes(this.state.categories[i].id)) {
                this.state.categories[i].checked = true;
            }

        }
    }

    displayImages(images) {
        let answer = [];
        for (let i = 0; i < images.length; i++) {
            answer.push(
                <Grid.Column mobile={16} tablet={8} computer={5}>
                    <Image src={"http://h2829428.stratoserver.net/images/blog-images/" + images[i]}
                           onClick={this.setThumbnail.bind(this, "" +
                               "http://h2829428.stratoserver.net/images/blog-images/" + images[i])}/>
                </Grid.Column>
            )
        }
        return answer;
    }

    valueChanged(type, e) {
        let value = e.target.value
        this.setState(prevState => ({...prevState, article: {...prevState.article, [type]: value}}));
    }

    showCategories() {
        return this.state.categories.map((category, index) =>
            <Form>
                <Form.Field style={{display: "flex", paddingRight: "15px"}} key={index}>
                    <Form.Checkbox checked={category.checked}
                                   onChange={this.changeCategory.bind(this, index)}
                                   label={category.name}
                    />
                </Form.Field>
            </Form>
        );
    }

    render() {
        const cImages = this.state.availableImages.map((image, index) =>
            <Grid.Row key={index}>
                <Grid.Column width={16}> {image.name} </Grid.Column>
                {this.displayImages(image.links)}
            </Grid.Row>
        );


        const showDates = this.state.articleDates.map((articleDate, index) =>
            <Grid.Column width={16} key={index}>
                <Segment>
                    <Form>
                        <Form.Group>
                            <Form.Field width={16}>
                                <label>Title</label>
                                <input value={articleDate.title}
                                       onChange={e => this.handleDateInputChange('title', index, e)}/>
                            </Form.Field>
                            <Form.Field>
                                <label>Datum</label>
                                <DatePicker
                                    selected={articleDate.date}
                                    dateFormat="dd.MM.yyyy"
                                    onChange={this.handleDateChange.bind(this, index)}
                                    className={"full-width"}
                                />
                            </Form.Field>
                        </Form.Group>
                        <Form.Field>
                            <label>Text</label>
                            <input value={articleDate.text}
                                   onChange={e => this.handleDateInputChange('text', index, e)}/>
                        </Form.Field>
                        <Button type='submit' icon='delete'
                                onClick={() => {
                                    this.state.articleDates.splice(index, 1);
                                    this.setState({...this.state});
                                }}/>
                    </Form>
                </Segment>
            </Grid.Column>
        );
        const showDownloads = this.state.downloads.map((articleDownload, index) =>
            <Grid.Column width={16} key={index}>
                <Segment>
                    <Form>
                        <Form.Group>
                            <Form.Field width={16}>
                                <label>Title</label>
                                <input value={articleDownload.title}
                                       onChange={e => this.handleDownloadInputChange('title', index, e)}/>
                            </Form.Field>
                        </Form.Group>
                        <Form.Group>
                            <Form.Field width={16}>
                                <label>Link</label>
                                <input className={this.state.linkWrong ?
                                    "input-field-wrong thumbnail-form" :
                                    "input-field thumbnail-form"}
                                       disabled={true}
                                       value={articleDownload.link}
                                       type="text"
                                       id="inputHeader"
                                       placeholder="URL"
                                />
                            </Form.Field>
                            <Form.Field>
                                <label className={"transparent-text"}>. </label>
                                <input type="file" id={"pdffile" + index} className="inputfile"
                                       onChange={e => this.uploadLink(index, e)}
                                       accept=".pdf, .PDF"/>
                                <Button color={"teal"}>
                                    <label htmlFor={"pdffile" + index}>
                                        Hochladen
                                    </label>
                                </Button>
                            </Form.Field>
                        </Form.Group>
                        <Form.Field>
                            <Button type='submit' icon='delete'
                                    onClick={() => {
                                        this.state.downloads.splice(index, 1);
                                        this.setState({...this.state});
                                    }}/>
                        </Form.Field>
                    </Form>
                </Segment>
            </Grid.Column>
        );
        let showImages = this.state.images.map((image, index) =>
            <Grid.Column width={5} key={"images" + index}>
                <Button attatched={"top"} fluid
                        onClick={() => {
                            this.state.images.splice(index, 1);
                            this.setState({...this.state});

                        }}>
                    Löschen
                </Button>
                <Image
                    fluid
                    src={image.link.replace('https://www.buergerstiftung-kreis-rv.de/','/')}
                />
            </Grid.Column>
        );

        return (
            <Grid>
                <Grid.Row>
                    <Grid.Column>
                        <h1 className="page-heading-text">{this.state.pageHeader}</h1>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <div className="breakline"/>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2}>
                    <Grid.Column width={16}>
                        <h2>Header</h2>
                    </Grid.Column>
                    <Grid.Column width={16}>
                        <input
                            className={this.state.headerWrong ? "input-field-wrong form-control thumbnail-form"
                                : "input-flied form-control thumbnail-form"}
                            value={this.state.article.header}
                            type="text"
                            id="inputHeader"
                            placeholder="Header"
                            onChange={e => this.valueChanged("header", e)}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={4}>
                    <Grid.Column width={16}>
                        <h2>Thumbnail</h2>
                    </Grid.Column>
                    <Grid.Column width={11} className="no-padding-right">
                        <div className="no-padding thumbnail-form">
                            <input className={this.state.thumbnailWrong ?
                                "input-flied-wrong thumbnail-form" :
                                "input-flied thumbnail-form"}
                                   value={this.state.article.thumbnail}
                                   type="text"
                                   id="inputthumbnail"
                                   placeholder="URL"
                                   onChange={e => this.valueChanged("thumbnail", e)}/>
                        </div>
                    </Grid.Column>
                    <Grid.Column width={5} className="no-padding">
                        <div className="no-padding thumbnail-form">
                            <input type="file" id="file" className="inputfile thumbnail-form"
                                   onChange={e => this.uploadThumbnailImage(e)}
                                   onclose={() => this.setState({...this.state})}
                                   accept="image/*"/>
                            <b>
                                <label htmlFor="file"
                                       className="call-to-action big-call-to-action thumbnail-form login-container">
                                    HOCHLADEN
                                </label>
                            </b>
                        </div>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2}>
                    <Grid.Column width={16}>
                        <h2>Einleitung</h2>
                    </Grid.Column>
                    <Grid.Column width={16}>
                        <input className={this.state.descriptionWrong ?
                            "input-flied-wrong form-control thumbnail-form" :
                            "input-flied form-control thumbnail-form"}
                               value={this.state.article.description}
                               type="text"
                               id="inputDescription"
                               placeholder="Beschreibung"
                               onChange={e => this.valueChanged("description", e)}
                        />
                    </Grid.Column>

                </Grid.Row>

                <Grid.Row columns={2}>
                    <Grid.Column width={16}>
                        <h2>Kategorien</h2>
                    </Grid.Column>
                    <Grid.Column width={16}>
                        <ul className="category-list">
                            {this.showCategories()}
                        </ul>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row columns={2}>
                    <Grid.Column width={16}>
                        <h2>Tags</h2>
                        <p>Tags werden mit einem "," separiert</p>
                    </Grid.Column>
                    <Grid.Column width={16}>
                        <input className={"input-flied form-control thumbnail-form"}
                               value={this.state.article.tags}
                               type="text"
                               id="inputTags"
                               placeholder="Projekte, eigene Projekte, andere Tags, ..."
                               onChange={e => this.valueChanged("tags", e)}
                        />
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row columns={2}>
                    <Grid.Column width={16}>
                        <h2>Text</h2>
                    </Grid.Column>
                    <Grid.Column width={16}>
                        <Form.Field style={{display: "inline-flex"}}>
                            <label style={{paddingRight: "5px"}}>Text als HTML bearbeiten:</label>
                            <Form.Radio toggle={true}
                                        checked={this.state.isEditSource}
                                        onChange={() => {
                                            this.setState(prevState => ({
                                                ...this.state,
                                                isEditSource: !prevState.isEditSource
                                            }));
                                        }}>
                            </Form.Radio>
                        </Form.Field>
                        <div style={{margin: "5px"}}/>
                        <div className="edit-wrapper">
                            {!this.state.isEditSource ? Editor && this.state.loaded &&
                                <Editor text={this.state.article.text}
                                        format={"html"}
                                        updateText={(text) => {
                                            let article = this.state.article;
                                            article.text = text;
                                            this.setState({
                                                ...this.state,
                                                article: article
                                            })
                                        }}/>
                                :
                                <Form.Field>
                                    <TextArea className={"edit-source-textarea"}
                                              value={this.state.article.text}
                                              onChange={e => this.valueChanged("text", e)}
                                    />
                                </Form.Field>}
                        </div>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <h2>Termine</h2>
                        <Grid.Row>
                            <Grid.Column>
                                <Button circular color='blue' icon='add' floated={"left"}
                                        onClick={() => this.addArticleDate()}/>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid.Column>
                    {showDates}
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <h2>Downloads</h2>
                        <Grid.Row>
                            <Grid.Column>
                                <Button circular color='blue' icon='add' floated={"left"}
                                        onClick={() => this.addArticleDownload()}/>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid.Column>
                    {showDownloads}
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <h2>Bildergallerie</h2>
                        <Grid.Row>
                            <Grid.Column width={5}>
                                <div className="no-padding thumbnail-form">
                                    <input type="file" id="galleryfile" className="inputfile thumbnail-form"
                                           onChange={e => this.uploadGalleryImage(e)}
                                           multiple
                                           accept="image/*"/>
                                    <b>
                                        <label htmlFor="galleryfile"
                                               className="call-to-action big-call-to-action thumbnail-form login-container">
                                            HOCHLADEN
                                        </label>
                                    </b>
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid.Column>
                    {showImages}
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Form.Checkbox indeterminate={false}
                                       style={{marginBottom:"5px"}}
                                       checked={this.state.article.showOnProjectsPage}
                                       label={'Auf Projekte Seite anzeigen'}
                                       onChange={(_,{checked}) => {
                                           this.setState({...this.state,
                                               article: {
                                                   ...this.state.article,
                                                   showOnProjectsPage: checked
                                               }
                                           })
                                       }}/>
                    </Grid.Column>
                    <Grid.Column width={16}>
                        <Form.Checkbox indeterminate={false}
                                       style={{marginBottom:"5px"}}
                                       checked={this.state.article.published}
                                       label={'Veröffentlichen'}
                                       onChange={this.publishedChanged.bind(this)}/>
                    </Grid.Column>
                    <Grid.Column width={16}>
                        <button className="call-to-action float-left"
                                onClick={this.createArticle.bind(this)}>
                            <strong> {this.state.buttonText} </strong>
                        </button>
                    </Grid.Column>
                </Grid.Row>
                <Modal open={this.state.showAvailableModal} onClose={this.handleClose.bind(this)}
                       className={"margin-auto"}>
                    <Modal.Content scrolling>
                        <Grid>
                            {cImages}
                        </Grid>
                    </Modal.Content>
                    <Modal.Actions>
                        <button className="call-to-action" onClick={this.handleClose.bind(this)}>Schließen</button>
                    </Modal.Actions>
                </Modal>
                <Modal open={this.state.errorModalOpen}
                       onClose={() => this.setState({...this.state, errorModalOpen: false})}
                       className={"margin-auto"}>
                    <Modal.Header>Error</Modal.Header>
                    <Modal.Content>
                        <p>{this.state.errorMessage}</p>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button positive icon='checkmark' labelPosition='right' content='OK'
                                onClick={() => {
                                    this.setState({
                                        ...this.state,
                                        errorModalOpen: false,
                                        errorMessage: ""
                                    });
                                }}/>
                    </Modal.Actions>
                </Modal>
            </Grid>
        );
    }
}

export default BlogAuthorEditPage